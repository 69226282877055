import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
// import CredencialCheckPage from "../pages/eventos/credenciamento/CredencialCheckPage";
// import LocalizacaoPage from "../pages/eventos/credenciamento/LocalizacaoPage";
// import PesagemPage from "../pages/eventos/pesagem/PesagemPage";
// import CertificatePage from "../pages/services/CertificatePage";
// import CredenciaisPage from "../pages/eventos/credenciais/CredenciaisPage";
// import NoticiasPageVersion2 from "../pages/noticias/NoticiasPageVersion2";
// import CredencialPage from "../pages/eventos/credenciamento/CredencialPage";
// import VirtualCredencialComponent from "../pages/eventos/credenciais/VirtualCredencialComponent";
// import ExcelImportPage from "../pages/eventos/ExcelImportPage";
// import CarterinhasPage from "../pages/services/carterinhas/CarteirinhasPage";
// import CarteirinhaCertificatePage from "../pages/services/carterinhas/CarteirinhaCertificatePage";
const ForgotPasswordPage = lazy(() => import("../pages/security/ForgotPasswordPage"));
const HomePage = lazy(() => import("../pages/HomePage"));
// const HomeFederacaoPage = lazy(() => import("../pages/HomeFederacao")); 
const LoginPage = lazy(() => import("../pages/security/LoginPage"));
const NotFoundPage = lazy(() => import("../pages/error/NotFoundPage"));
const PrivacyPoliticsPage = lazy(() => import("../pages/PrivacyPoliticsPage"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const SignupPage = lazy(() => import("../pages/security/SignupPage"));
const ChangePasswordPage = lazy(() => import("../pages/security/ChangePasswordPage"));
const ChangeEmailPage = lazy(() => import("../pages/security/ChangeEmailPage"));
const ChangeNamePage = lazy(() => import("../pages/security/ChangeNamePage"));
const UnauthorizedPage = lazy(() => import("../pages/error/UnauthorizedPage"));
const NotVerifiedPage = lazy(() => import("../pages/security/NotVerfiedPage"));

const AddUsersGroupPage = lazy(() => import("../pages/security/UsersGroups/AddUsersGroupPage"));
const UsersGroupsPage = lazy(() => import("../pages/security/UsersGroups/UsersGroupsPage"));
const AddUserPage = lazy(() => import("../pages/security/users/AddUserPage"));
const UsersPage = lazy(() => import("../pages/security/users/UsersPage"));
// const VerifyEmailPage = lazy(() => import("../pages/security/VerifyEmailPage"));
const PraticantesPage = lazy(() => import("../pages/registers/praticantes/PraticantesPage"));
const AddPraticantesPage = lazy(() => import("../pages/registers/praticantes/AddPraticantesPage"));
const AddContaPage = lazy(() => import("../pages/financial/AddContaPage"));
const ContasReceberPage = lazy(() => import("../pages/financial/receber/ContasReceberPage"));
const ContasPagarPage = lazy(() => import("../pages/financial/pagar/ContasPagarPage"));
const PrintImagePage = lazy(() => import("../pages/PrintImagePage"));
const PrintReciboPage = lazy(() => import("../pages/financial/PrintReciboPage"));
const PrintFaturaPage = lazy(() => import("../pages/financial/PrintFaturaPage"));
const SieHomePage = lazy(() => import("../pages/sie/SieHomePage"));
const InstitucionalHomePage = lazy(() => import("../pages/institucional/InstitucionalHomePage"));
const TransparenciaHomePage = lazy(() => import("../pages/transparencia/TransparenciaHomePage"));
const EducativoHomePage = lazy(() => import("../pages/educativo/EducativoHomePage"));
const EntidadesPage = lazy(() => import("../pages/registers/entidades/EntidadesPage"));
const AddEntidadePage = lazy(() => import("../pages/registers/entidades/AddEntidadePage"));
const ServicesPage = lazy(() => import("../pages/services/taxas/TaxasPage"));
const AddServicePage = lazy(() => import("../pages/services/taxas/AddServicePage"));
const CertificationsPage = lazy(() => import("../pages/certificados/CertificationPage"));
const CertificatePage = lazy(() => import("../pages/services/CertificatePage"));
const TransferenciasPage = lazy(() => import("../pages/services/transferencias/TransferenciasPage"));
const CredencialPage = lazy(() => import("../pages/eventos/credenciamento/CredencialPage"));
const CredencialCheckPage = lazy(() => import("../pages/eventos/credenciamento/CredencialCheckPage"));
const LocalizacaoPage = lazy(() => import("../pages/eventos/credenciamento/LocalizacaoPage"));
const PesagemPage = lazy(() => import("../pages/eventos/pesagem/PesagemPage"));
// const VirtualCredencialComponent = lazy(() => import("../pages/eventos/credenciamento/VirtualCredencialComponent"));
// const AddNoticiaPage = lazy(() => import("../pages/noticias/AddNoticiaPage"));
const DepartamentosPage = lazy(() => import("../pages/registers/departamentos/DepartamentosPage"));
const AddDepartamentoPage = lazy(() => import("../pages/registers/departamentos/AddDepartamentoPage"));
// const NoticiasPage = lazy(() => import("../pages/noticias/NoticiasPage"));
// const ViewNoticiaPage = lazy(() => import("../pages/noticias/ViewNoticiaPage"));
const NoticiasPageVersion2 = lazy(() => import("../pages/noticias/NoticiasPageVersion2"));
const ViewNoticiaPageVersion2 = lazy(() => import("../pages/noticias/ViewNoticiaPageVersion2"));
const PrintCertificadoPage = lazy(() => import("../pages/certificados/PrintCertificadoPage"));
const GaleriaPage = lazy(() => import("../pages/galeria/GaleriaPage"));
const AddGaleriaPage = lazy(() => import("../pages/galeria/AddGaleriaPage"));
const ViewGaleriaPage = lazy(() => import("../pages/galeria/ViewGaleriaPage"));
const CalendarioPage = lazy(() => import("../pages/calendario/CalendarioPage"));
const AddCalendarioPage = lazy(() => import("../pages/calendario/AddCalendarioPage"));
const AddEventoPage = lazy(() => import("../pages/eventos/AddEventoPage"));
const AddPassagensFaixaPage = lazy(() => import("../pages/eventos/passagensFaixa/AddPassagemFaixaPage"));
// const EventosPage = lazy(() => import("../pages/eventos/EventosPage"));
const ViewEventoPage = lazy(() => import("../pages/eventos/ViewEventoPage"));
const PublicEventosPage = lazy(() => import("../pages/eventos/PublicEventosPage"));
const InscricaoEventoPage = lazy(() => import("../pages/eventos/InscricaoEventoPage"));
const InscricaoEventoPassagemFaixaPage = lazy(() => import("../pages/eventos/passagensFaixa/InscricaoEventoPassagemFaixaPage"));
const CredenciamentoPage = lazy(() => import("../pages/eventos/credenciamento/CredenciamentoPage"));
const SelecaoPage = lazy(() => import("../pages/selecoes/SelecaoPage"));
const AddSelecaoKyoruguiPage = lazy(() => import("../pages/selecoes/AddSelecaoKyoruguiPage"));

const HelpdeskHomePage = lazy(() => import("../pages/helpdesk/HelpdeskHomePage"));
const TicketsPage = lazy(() => import("../pages/helpdesk/TicketsPage"));
const EditTicketPage = lazy(() => import("../pages/helpdesk/EditTicketPage"));
const FaqPage = lazy(() => import("../pages/helpdesk/FaqPage"));
const EditFaqPage = lazy(() => import("../pages/helpdesk/EditFaqPage"));
const TutoriaisPage = lazy(() => import("../pages/helpdesk/TutoriaisPage"));
const EditTutoriaisPage = lazy(() => import("../pages/helpdesk/EditTutoriaisPage"));

// const Style1ViewPage = lazy(() => import("../pages/conteudo/Style1ViewPage"));
const Style2ViewPage = lazy(() => import("../pages/conteudo/Style2ViewPage"));
// const Style1AddPage = lazy(() => import("../pages/conteudo/Style1AddPage"));
const Style2AddPage = lazy(() => import("../pages/conteudo/Style2AddPage"));
const SorteioPage = lazy(() => import("../pages/sorteio/SorteioPage"));

const ViewServicesPage = lazy(() => import("../pages/institucional/ViewTaxasPage"));
const ViewFederacoesPage = lazy(() => import("../pages/institucional/ViewFederacoesPage"));
const ViewPraticantesPage = lazy(() => import("../pages/institucional/ViewPraticantesPage"));
const ViewAssociacoesPage = lazy(() => import("../pages/institucional/ViewAssociacoesPage"));
const RelatorioInscricoesEventoPage = lazy(() => import("../pages/eventos/RelatorioInscricoesEventoPage"));
// const PrintCobrancaQRCodePage = lazy(() => import("../pages/eventos/PrintCobrancaQRCodePage")); 

const CarteirinhaCertificatePage = lazy(() => import("../pages/services/carterinhas/CarteirinhaCertificatePage"));
const CarterinhasPage = lazy(() => import("../pages/services/carterinhas/CarteirinhasPage"));
const CarteirinhaVirtualPage = lazy(() => import("../pages/carteirinhaVirtual/CarteirinhaVirtualModalComponent"));

const ViewRankingPage = lazy(() => import("../pages/ranking/ViewRankingPage"));

const ApoiadoresPage = lazy(() => import("../pages/apoiadores/ApoiadoresPage"));
const AddApoiadoresPage = lazy(() => import("../pages/apoiadores/AddApoiadoresPage"));
// const { useEffect } = lazy(() => import("react")); 
// const { useDispatch } = lazy(() => import("react-redux")); 
// const { useLocation } = lazy(() => import("react-router-dom")); 
// const { GetSubDomain } = lazy(() => import("../utils/urlSudDomainUtil")); 

export default function AppRouter(props) {
  // const dispatch = useDispatch();
  // //console.log("Passou aqui!");
  // useEffect(() => {
  //   //console.log("Passou aqui!");
  // }, []);
  // useEffect(() => {
  //   const host = window.location.host;
  //   const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
  //   if (arr.length > 0) {
  //     //console.log("arr[0]=", arr[0]);
  //     dispatch({ type: "SUBDOMAIN", payload: arr[0] });
  //   }
  // }, []);
  // //console.log("window.location.host.split('.')[0]=", window.location.host.split(".")[0]);
  // //console.log("Passou aqui!");
  // let location = useLocation();
  // //console.log("useLocation()=", useLocation());
  // let subDomainsCollection = ["fctkd", "rstkd", "prtkd"];
  // let subDomain = subDomainsCollection.find((subDomainItem) => location.pathname.includes(subDomainItem));
  // //console.log("location=", location);
  // let subDomainsCollection = ["fctkd", "rstkd", "prtkd"];
  // let subDomain = subDomainsCollection.find((subDomainItem) => location.pathname.includes(subDomainItem));
  let subDomain = ""; //GetSubDomain();
  // let location = useLocation();

  if (subDomain.length > 0) subDomain = "/" + subDomain;
  else subDomain = "";
  // //console.log("appRouter -> subDomain =", subDomain);
  //if (subDomainsCollection.find(subDomainItem => (location.pathname.includes(subDomainItem)))

  //let subDomain = "";
  // useEffect(() => {
  //   if (location.pathname.includes("fctkd")) {
  //     navigate(location.pathname.replace("/fctkd", ""), { replace: true });
  //     // //console.log("location.pathname.replace('/fctkd','')=", location.pathname.replace("/fctkd", ""));
  //   }
  // });

  return (
    <>
      <Suspense fallback={<div>Carregando...</div>}>
        <Routes>
          {/* {console.log("Passou aqui!")} */}
          {/* {window.location.host.split(".")[0] === "sie" ? (
          <Route exact path={`${subDomain}/sie/`} element={<SieHomePage />} />
        ) : window.location.host.split(".")[0] === "dashboard" ? (
          <Route exact path={`${subDomain}/sie/dashboard`} element={<SieHomePage />} />
        ) : window.location.host.split(".")[0] === "institucional" ? (
          <Route exact path={`${subDomain}/institucional/`} element={<InstitucionalHomePage />} />
        ) : window.location.host.split(".")[0] === "transparencia" ? (
          <Route exact path={`${subDomain}/transparencia/`} element={<TransparenciaHomePage />} />
        ) : window.location.host.split(".")[0] === "educativo" ? (
          <Route exact path={`${subDomain}/educativo/`} element={<EducativoHomePage />} />
        ) : (
          <Route exact path={`${subDomain}/`} element={<HomePage />} />
        )} */}
          {/* {window.location.host.split(".")[0] === "fctkd" && false ? (
          <Route exact path={`${subDomain}/`} element={<HomeFederacaoPage />} />
        ) : ( */}
          <Route exact path={`${subDomain}/`} element={<HomePage />} />
          {/* )} */}
          {/* <Route exact path={`${subDomain}/`} element={<HomePage />} /> */}
          <Route exact path={`${subDomain}/institucional`} element={<InstitucionalHomePage />} />
          <Route exact path={`${subDomain}/transparencia`} element={<TransparenciaHomePage />} />
          <Route exact path={`${subDomain}/cbtkdeduca`} element={<EducativoHomePage />} />
          <Route exact path={`${subDomain}/sie`} element={<SieHomePage />} />
          <Route exact path={`${subDomain}/sie/dashboard`} element={<SieHomePage />} />
          <Route exact path={`${subDomain}/login`} element={<LoginPage />} />
          <Route exact path={`${subDomain}/signup`} element={<SignupPage />} />
          {/* <Route exact path={`${subDomain}/signup-validation`} element={<VerifyEmailPage />} /> */}
          <Route exact path={`${subDomain}/signup-validation`} element={<HomePage />} />
          {/* <Route exact path={`${subDomain}/excelimport`} element={<ExcelImportPage />} /> */}

          <Route
            exact
            path={`${subDomain}/profile`}
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/carteirinha-virtual`}
            element={
              <ProtectedRoute>
                <CarteirinhaVirtualPage />
              </ProtectedRoute>
            }
          />
          {/* <ProtectedRoute exact path='/profile' element={<ProfilePage/>}/> */}
          <Route exact path={`${subDomain}/forgot-password`} element={<ForgotPasswordPage />} />
          <Route exact path={`${subDomain}/change-password`} element={<ChangePasswordPage />} />
          <Route exact path={`${subDomain}/change-Email`} element={<ChangeEmailPage />} />
          <Route exact path={`${subDomain}/change-Name`} element={<ChangeNamePage />} />
          <Route exact path={`${subDomain}/privacy-politics`} element={<PrivacyPoliticsPage />} />
          <Route
            exact
            path={`${subDomain}/sie/administracao/usersgroups`}
            element={
              <ProtectedRoute>
                <UsersGroupsPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/administracao/addusersgroup`}
            element={
              <ProtectedRoute>
                <AddUsersGroupPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/administracao/users`}
            element={
              <ProtectedRoute>
                <UsersPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/administracao/adduser`}
            element={
              <ProtectedRoute>
                <AddUserPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/cadastros/pessoas`}
            element={
              <ProtectedRoute>
                <PraticantesPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/cadastros/addpraticante`}
            element={
              <ProtectedRoute>
                <AddPraticantesPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/financeiro/addconta`}
            element={
              <ProtectedRoute>
                <AddContaPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/financeiro/receitas`}
            element={
              <ProtectedRoute>
                <ContasReceberPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/financeiro/despesas`}
            element={
              <ProtectedRoute>
                <ContasPagarPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/cadastros/entidades`}
            element={
              <ProtectedRoute>
                <EntidadesPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/cadastros/entidades/addentidade`}
            element={
              <ProtectedRoute>
                <AddEntidadePage />
              </ProtectedRoute>
            }
          />

          {/* <Route
          exact
          path={`${subDomain}/sie/eventos`}
          element={
            <ProtectedRoute>
              <EventosPage />
            </ProtectedRoute>
          }
        /> */}
          <Route
            exact
            path={`${subDomain}/eventos/addpassagensfaixa`}
            element={
              <ProtectedRoute>
                <AddPassagensFaixaPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/eventos/addevento`}
            element={
              <ProtectedRoute>
                <AddEventoPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/eventos/inscricao`}
            element={
              <ProtectedRoute>
                <InscricaoEventoPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/eventos/inscricaoexamefaixa`}
            element={
              <ProtectedRoute>
                <InscricaoEventoPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/eventos/inscricaoexamekukkiwon`}
            element={
              <ProtectedRoute>
                <InscricaoEventoPassagemFaixaPage />
              </ProtectedRoute>
            }
          />
          {/* <Route exact path={`${subDomain}/eventos/printcobrancaqrcode`} element={<PrintCobrancaQRCodePage />} /> */}
          <Route exact path={`${subDomain}/eventos/evento`} element={<ViewEventoPage />} />
          <Route exact path={`${subDomain}/eventos`} element={<PublicEventosPage />} />
          <Route exact path={`${subDomain}/competicoes`} element={<PublicEventosPage />} />
          <Route exact path={`${subDomain}/examesdefaixa`} element={<PublicEventosPage />} />
          <Route exact path={`${subDomain}/cursos`} element={<PublicEventosPage />} />
          <Route exact path={`${subDomain}/seminarios`} element={<PublicEventosPage />} />
          <Route exact path={`${subDomain}/eventos/examesdefaixa`} element={<PublicEventosPage />} />
          <Route exact path={`${subDomain}/eventos/cursos`} element={<PublicEventosPage />} />
          <Route exact path={`${subDomain}/eventos/seminarios`} element={<PublicEventosPage />} />
          {/* <Route exact path={`${subDomain}/eventos/inscritos`} element={<RelatorioInscricoesEventoPage />} /> */}
          <Route exact path={`${subDomain}/eventos/inscritos/listaprevia`} element={<RelatorioInscricoesEventoPage />} />
          {/* <Route exact path={`${subDomain}/eventos/credenciais`} element={<CredenciaisPage />} /> */}
          <Route
            exact
            path={`${subDomain}/eventos/evento/credenciamento`}
            element={
              <ProtectedRoute>
                <CredenciamentoPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/eventos/evento/credencial`}
            element={
              <ProtectedRoute>
                <CredencialPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/eventos/evento/credencialcheck`}
            element={
              <ProtectedRoute>
                <CredencialCheckPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/eventos/evento/localizacao`}
            element={
              <ProtectedRoute>
                <LocalizacaoPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/eventos/evento/pesagem`}
            element={
              <ProtectedRoute>
                <PesagemPage />
              </ProtectedRoute>
            }
          />





          <Route
            exact
            path={`${subDomain}/sie/financeiro/services`}
            element={
              <ProtectedRoute>
                <ServicesPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/financeiro/addservice`}
            element={
              <ProtectedRoute>
                <AddServicePage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/servicos/certificados`}
            element={
              <ProtectedRoute>
                <CertificationsPage />
              </ProtectedRoute>
            }
          />
          <Route exact path={`${subDomain}/validacao/certificado`} element={<CertificatePage />} />
          <Route
            exact
            path={`${subDomain}/sie/servicos/carterinhas`}
            element={
              <ProtectedRoute>
                <CarterinhasPage />
              </ProtectedRoute>
            }
          />
          <Route exact path={`${subDomain}/validacao/carteira`} element={<CarteirinhaCertificatePage />} />
          <Route
            exact
            path={`${subDomain}/sie/servicos/transferencias`}
            element={
              <ProtectedRoute>
                <TransferenciasPage />
              </ProtectedRoute>
            }
          />


          <Route
            exact
            path={`${subDomain}/sie/administracao/adddepartamento`}
            element={
              <ProtectedRoute>
                <AddDepartamentoPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/sie/administracao/departamentos`}
            element={
              <ProtectedRoute>
                <DepartamentosPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/noticias/addnoticia`}
            element={
              <ProtectedRoute>
                <Style2AddPage />
              </ProtectedRoute>
            }
          />
          <Route exact path={`${subDomain}/noticias/noticia`} element={
            <ViewNoticiaPageVersion2 />
          } />
          <Route exact path={`${subDomain}/noticias`} element=
            {
              <NoticiasPageVersion2 />
            }
          />
          <Route exact path={`${subDomain}/noticias/filiados`} element={<ViewPraticantesPage />} />

          <Route
            exact
            path={`${subDomain}/apoiadores`}
            element={
              <ProtectedRoute>
                <ApoiadoresPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/apoiadores/addapoiador`}
            element={
              <ProtectedRoute>
                <AddApoiadoresPage />
              </ProtectedRoute>
            }
          />

          <Route exact path={`${subDomain}/selecoes`} element={<SelecaoPage />} />
          <Route
            exact
            path={`${subDomain}/selecoes/kyorugui/addselecao`}
            element={
              <ProtectedRoute>
                <AddSelecaoKyoruguiPage />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path={`${subDomain}/galeria/addimagem`}
            element={
              <ProtectedRoute>
                <AddGaleriaPage />
              </ProtectedRoute>
            }
          />

          <Route exact path={`${subDomain}/galeria/album`} element={<ViewGaleriaPage />} />
          <Route exact path={`${subDomain}/galeria`} element={<GaleriaPage />} />

          <Route
            exact
            path={`${subDomain}/calendario/addagendamento`}
            element={
              <ProtectedRoute>
                <AddCalendarioPage />
              </ProtectedRoute>
            }
          />
          <Route exact path={`${subDomain}/calendario`} element={<CalendarioPage />} />

          <Route
            exact
            path={`${subDomain}/helpdesk`}
            element={
              <ProtectedRoute>
                <HelpdeskHomePage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/helpdesk/faqs`}
            element={
              <ProtectedRoute>
                <FaqPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/helpdesk/editfaq`}
            element={
              <ProtectedRoute>
                <EditFaqPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/helpdesk/tutoriais`}
            element={
              <ProtectedRoute>
                <TutoriaisPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/helpdesk/edittutorial`}
            element={
              <ProtectedRoute>
                <EditTutoriaisPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/helpdesk/tickets`}
            element={
              <ProtectedRoute>
                <TicketsPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path={`${subDomain}/helpdesk/editticket`}
            element={
              <ProtectedRoute>
                <EditTicketPage />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path={`${subDomain}/content/addcontent`}
            element={
              <ProtectedRoute>
                <Style2AddPage />
              </ProtectedRoute>
            }
          />

          <Route exact path={`${subDomain}/preview/ranking`} element={<ViewRankingPage />} />

          <Route exact path={`${subDomain}/preview/sorteio`} element={
            <SorteioPage />
          } />

          <Route exact path={`${subDomain}/content`} element={
            <Style2ViewPage />
          } />
          <Route exact path={`${subDomain}/sie/content`} element={
            <Style2ViewPage />
          } />
          <Route exact path={`${subDomain}/transparencia/content`} element={<Style2ViewPage />} />

          <Route exact path={`${subDomain}/institucional/content`} element={<Style2ViewPage />} />
          <Route exact path={`${subDomain}/institucional/regimentotaxas`} element={<ViewServicesPage />} />
          <Route
            exact
            path={`${subDomain}/institucional/regimentotaxas/addservice`}
            element={
              <ProtectedRoute>
                <AddServicePage />
              </ProtectedRoute>
            }
          />

          <Route exact path={`${subDomain}/institucional/federacoes`} element={<ViewFederacoesPage />} />
          <Route
            exact
            path={`${subDomain}/institucional/federacoes/addfederacao`}
            element={
              <ProtectedRoute>
                <AddEntidadePage />
              </ProtectedRoute>
            }
          />

          <Route exact path={`${subDomain}/institucional/filiados`} element={<ViewPraticantesPage />} />
          <Route
            exact
            path={`${subDomain}/institucional/filiados/addfiliado`}
            element={
              <ProtectedRoute>
                <AddPraticantesPage />
              </ProtectedRoute>
            }
          />
          <Route exact path={`${subDomain}/institucional/associacoes`} element={<ViewAssociacoesPage />} />
          <Route
            exact
            path={`${subDomain}/institucional/associacoes/addassociacao`}
            element={
              <ProtectedRoute>
                <AddEntidadePage />
              </ProtectedRoute>
            }
          />

          <Route exact path={`${subDomain}/cbtkdeduca/content`} element={<Style2ViewPage />} />

          <Route exact path={`${subDomain}/sie/printimage`} element={<PrintImagePage />} />
          <Route exact path={`${subDomain}/sie/financeiro/printrecibo`} element={<PrintReciboPage />} />
          <Route exact path={`${subDomain}/sie/financeiro/printfatura`} element={<PrintFaturaPage />} />
          <Route exact path={`${subDomain}/sie/certificados/printcertificado`} element={<PrintCertificadoPage />} />

          {/* <Route exact path={`${subDomain}/394857`} element={<Navigate to="/public/394857/" />} /> */}
          <Route exact path={`${subDomain}/kukkiwon`} element={<Navigate to="/public/kukkiwon/" />} />

          <Route exact path={`${subDomain}/notverified`} element={<NotVerifiedPage />} />
          <Route exact path={`${subDomain}/unauthorized`} element={<UnauthorizedPage />} />
          <Route exact path={`${subDomain}*`} element={<NotFoundPage />} />
        </Routes>
      </Suspense>
      {/* {console.log("Passou aqui!")} */}
    </>
  );
}
