import React, { useRef } from "react";
import { useEffect, useState, Suspense, lazy } from "react";
import AppRouter from "./routes/AppRouter";
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, combineReducers } from "redux";
import { AuthContextProvider } from "./contexts/AuthContext";
import Layout from "./pages/Layout";
import rulesReducer from "./reducers/rulesReducer";
// import cookiesReducer from "./reducers/cookiesReducer";
import subDomainReducer from "./reducers/subDomainReducer";
import { Provider } from "react-redux";
// import { useSelector, useDispatch } from "react-redux";
// import SecurityRulesService from "./services/SecurityRules.service";
// import portaisService from "./services/portais.service";
// import ServiceCategoryService from "./services/ServiceCategory.service";
// import { getUrlDetails } from "./utils/UrlDomainUtil";
import { GetSubDomain } from "./utils/urlSudDomainUtil";
// import { useLocation } from "react-router-dom";
import Tooltip from "bootstrap/js/dist/tooltip";
// import axios from "axios";
// import {removeAccents} from "./utils/removeAccents";
// import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const HighlighterSpeakerComponent = lazy(() => import("./components/Elementos/HighlighterSpeakerComponent"));
const WhatsAppWidget = lazy(() => import("react-whatsapp-chat-widget"));
// const synth = window.speechSynthesis;
const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
const tooltipList = [...tooltipTriggerList].map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));

function App() {

  // //console.log("App -> removeAccents('ÇçàãáèẽéìĩíòõóùũúÀÃÁÈẼÉÌĨÍòÕÓÙŨÚ')=", removeAccents('ÇçàãáèẽéìĩíòõóùũúÀÃÁÈẼÉÌĨÍòÕÓÙŨÚ'));


  /*
  * Portals installation
  */
  // let justOneExecution = useRef(true);
  // useEffect(() => {
  //   // portaisService.installPortals();
  //   // portaisService.installPortalHelpDesk();
  //   if (justOneExecution.current) {
  //     justOneExecution.current = false;
  //     // portaisService.installPortalsDasFederacoes("AsQvewhhLXE65y5ryMPG"); // FPTO

  //   }
  // }, []);

  /*
   * Security rules installation
   */
  // useEffect(() => {
  //   SecurityRulesService.installsecurityrules();
  // }, []);

  /*
   * Services Category installation
   */
  // useEffect(() => {
  //   ServiceCategoryService.installServicesCategory();
  // }, []);

  const allReducers = combineReducers({
    userRules: rulesReducer,
    subDomain: subDomainReducer,
  });
  const store = createStore(allReducers);

  // const dispatch = useDispatch();
  // function registerSubDomain(value) {
  //   // useDispatchSubDomain({ type: "SUBDOMAIN", payload: subDomainValue });
  //   dispatch({ type: "SUBDOMAIN", payload: value });
  // }

  const [subDomain, setSubDomain] = useState(null);
  useEffect(() => {
    // getUrlDetails().then((urlDetails) => {
    //   //console.log("urlDetails=", urlDetails);
    //   setSubDomain(urlDetails.portal);
    // });
    // GetSubDomain().then((urlSubDomain) => {
    //   //console.log("urlDetails=", urlSubDomain);
    //   setSubDomain(urlSubDomain);
    // });
    setSubDomain(GetSubDomain());
    // //console.log("App -> GetSubDomain()=", GetSubDomain());
  }, []);


  // {console.log("REACT_APP_ENV_SPEC_NAME=", process.env.REACT_APP_ENV_SPEC_NAME)}
  // {console.log("REACT_APP_ENV=", process.env.REACT_APP_ENV)}
  // {console.log("NODE_ENV=", process.env.NODE_ENV)}
  // {/* {console.log("REACT_APP_SITE_KEY=", process.env.REACT_APP_SITE_KEY)} */}
  // //console.log("REACT_APP_BACKEND_API_URL=", process.env.REACT_APP_BACKEND_API_URL);

  // Fazer um fetch no endereco https://sg-cbtkd.web.app/api/gnet e exibir o resultado com o //console.log
  // fetch("https://cbtkd.org.br:443/api/gnet/")
  // .then((resposta) => {
  //   //console.log(resposta);
  //   return resposta.json();
  // })
  // .then((resposta) => {
  //   //console.log(resposta);
  // });

  // fetch(process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/")
  // .then((resposta) => {
  //   //console.log(resposta);
  //   // return resposta.json();
  // });
  // .then((resposta) => {
  //   //console.log(resposta);
  // });

  // fetch(process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/serverstatus/")
  // .then((resposta) => {
  //   //console.log(resposta);
  //   //console.log(resposta.body);
  //   // return resposta.json();
  // });
  // .then((resposta) => {
  //   //console.log(resposta);
  // });

  // Make a GET request
  // try {
  //   axios({
  //     method: 'get',
  //     url: process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/",
  //   }).then((resposta) => {
  //     //console.log(resposta);
  //     //console.log(resposta.data);
  //   }).catch((error) => {
  //     //console.log(error);
  //   });
  // }
  // catch (error) {
  //   //console.log(error);
  // }

  // try {
  //   axios({
  //     method: 'get',
  //     url: process.env.REACT_APP_BACKEND_API_URL + "/api/gnet/serverstatus/",
  //   }).then((resposta) => {
  //     //console.log(resposta);
  //     //console.log(resposta.data);
  //   }).catch((error) => {
  //     //console.log(error);
  //   });
  // }
  // catch (error) {
  //   //console.log(error);
  // }

  return (
    <>
      {/* {console.log("Passou aqui!")} */}
      <Provider store={store}>
        {/* {console.log("1.Passou aqui!")} */}
        <AuthContextProvider subdomain={subDomain}>
          {/* {console.log("2.Passou aqui!")} */}
          <Router>
            {/* {console.log("3.Passou aqui!")} */}
            <Layout subdomain={subDomain}>
              {/* {console.log("4.Passou aqui!")} */}
              <AppRouter subdomain={subDomain} />
              <Suspense fallback={<div></div>}>
                <HighlighterSpeakerComponent />
              </Suspense>
              {(process.env.REACT_APP_ENV === "development" || window.location.host.indexOf("preview-sg-cbtkd.web.app") !== -1) && (
                <Suspense fallback={<div></div>}>
                  <WhatsAppWidget
                    headerIcon="https://firebasestorage.googleapis.com/v0/b/sg-cbtkd.appspot.com/o/images%2FLOGO281675307113617?alt=media&token=541941a4-0467-4c6b-801c-00cc361e8308"
                    headerTitle="CBTKD"
                    phoneNo="+5548996295538"
                    chatPersonName="CBTKD"
                    chatMessage="Olá! Como podemos ajudar?"
                    headerCaption="Normalmente responde em poucos minutos"
                    btnTxt="Iniciar conversa"
                    placeholder="Digite sua mensagem"
                  // showPopup={true}
                  // profileAvatar="https://cbtkd.org.br:443/static/media/logo.1e0f5f5b.png"
                  // profileName="CBTKD"
                  />
                </Suspense>
              )}
            </Layout>
          </Router>
        </AuthContextProvider>
      </Provider>
      {/* {console.log("Passou aqui!")} */}
    </>
  );
}

export default App;
